import React from "react";
import PageTitle from "../../components/page-tittle/PageTitle";

const Error = () => {
    return (
        <>
            <PageTitle current={'404 Not Found'} />
        </>
    );
}
export default Error;