import React, { useEffect, useState } from "react";
import PageTitle from "../../components/page-tittle/PageTitle";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useShoppingCart } from "../../context/ShoppingCartContext";
import apiConfig from "../../config/apiConfig";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ShopCheckout = () => {
  const [orders, setOrders] = useState();
  const [total, setTotal] = useState();
  const [shipToDifferentAddress, setShipToDifferentAddress] = useState(false);
  const [stateOptions, setStateOptions] = useState({});
  const [countriesOptions, setCountriesOptions] = useState();
  const [citiesOptions, setCitiesOptions] = useState({});
  const [datta, setDatta] = useState();
  const [buttonClicked, setButtonClicked] = useState(false);
  const { showSuccessToastMessage, showInfoToastMessage } = useShoppingCart();
  const [costs, setCosts] = useState({});

  const { t } = useTranslation();
  const [state, setState] = useState({});

  const handleChange = (e) => {
    setState(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
    if (e.target.name === 'country') getStates(e.target.value, 'billing');
  }
  useEffect(() => console.log(state), [state]);

  const [shipState, setShipState] = useState({});

  const handlesChange = (e) => {
    setShipState(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
    if (e.target.name === 'country') getStates(e.target.value, 'shipping');
    // if (e.target.name === 'state') getCities(e.target.value, 'shipping');
  }




  const handleCheckboxChange = (e) => {
    const newValue = e.target.checked; // Get the new value of the checkbox
    setShipToDifferentAddress(newValue); // Update the state
    // setShipState(state);
  };

  const addOrUpdateBillingAddress = () => {
    const bearerToken = localStorage.getItem("accessToken");
    const formdata = {
      name: state?.name,
      address: state?.address1,
      email: state?.email,
      phone: state?.phone,
      type: state?.type || 'other',
      pincode: state?.postcode,
      country_id: state?.country,
      state_id: state?.state,
      city_id: 0,
      defaddress: "1",
    };
    const api = apiConfig.createBillingAddress;
    fetch(api, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
      body: JSON.stringify(formdata),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result)
        if (result.status === 'success') {
          toast.success(result?.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          window.location.reload();
        } else {
          toast.warning("Please fill in all details", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }

      })
      .catch((error) => console.log("error", error));
  };

  const addOrUpdateAddress = () => {
    const bearerToken = localStorage.getItem("accessToken");
    const formdata = {
      name: shipState?.name,
      address: shipState?.address,
      email: shipState?.email,
      phone: shipState?.phone,
      type: "Other",
      pincode: shipState?.postcode,
      country_id: shipState?.country,
      state_id: shipState?.state,
      city_id: 0,
      defaddress: "1",
    };

    console.log("phirAur", formdata);
    fetch(apiConfig.createAddressAPI, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
      body: JSON.stringify(formdata),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 'success') {
          console.log(result);
          toast.success(result?.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          window.location.reload();
        } else if (result.status === false) {
          showInfoToastMessage('Fill all details');
        }

      })
      .catch((error) => console.log("error", error));
  };
  const navigate = useNavigate()

  const placeOrder = (e) => {
    e.preventDefault();
    setButtonClicked(true);
    const bearerToken = localStorage.getItem("accessToken");

    if (orders.length === 0) {
      showInfoToastMessage('Please add products')
      setButtonClicked(false);
    } else if (!state.id) {
      showInfoToastMessage('Please add billing address')
      setButtonClicked(false);

    } else if (!shipState.id && !shipToDifferentAddress) {
      showInfoToastMessage('Please add shipping address')
      setButtonClicked(false);

    }
    else {
      var formdata = {};
      if (shipToDifferentAddress) {
        formdata = {
          grand_total: datta?.grand_total,
          billing_id: state?.id,
          shipping_id: shipState?.id ? shipState.id : '',
          sameAsBilling: 1
        };
      } else {
        formdata = {
          grand_total: datta?.grand_total,
          billing_id: state?.id,
          shipping_id: shipState?.id,
        };
      }

      fetch(apiConfig.checkoutAPI, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearerToken}`,
        },
        body: JSON.stringify(formdata),
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.success === true) {
            toast.success(result.message, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setTimeout(() => {
              // window.location.href = `/account?activeTab=orders&orderId=${"EODD" + result?.order_id}`;
              navigate(`/order-details?orderID=${result.inc_order_id}`)
            }, 1000)
          } else {
            setButtonClicked(false);
            showInfoToastMessage(result.message)
          }

        })
        .catch((error) => console.log("error", error));
    }

  };

  function getCartDetails() {
    const bearerToken = localStorage.getItem("accessToken");
    // console.log("bearerToken", orders);
    const api = apiConfig.getCartDataAPI;

    fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          if (data.data.length === 0) {
            navigate('/products');
          }
          setCosts({
            grand_total: data.grand_total,
            sub_total: data.total,
            tax: data.total_tax_amount,
            discount: data.discount_amount,
            shipping: data.shipping_charge,
          })
          setTotal(data.total);
          setDatta(data);
          setOrders([]);
          data.data.forEach(product => {
            var prod = {};
            if (product.simple_product) {
              prod = {
                price: product?.simple_product?.offer_price === null ? product.simple_product.price : product.simple_product.offer_price,
                qty: product.qty,
                name: product.simple_product.product_name.en,
                image_path: product.simple_product.image_path,
                image: product.simple_product.product_image?.[0],
                link: `/product-details?product_id=${product.simple_product?.id}`,
              }
            } else if (product.product) {
              prod = {
                price: product?.variant?.offer ? product.variant.offer : product.variant.price,
                qty: product.qty,
                name: product.product.product_name.en,
                image_path: product.product.image_path,
                image: product.variant.variantimages.main_image,
                link: `/product-details?product_id=${product.product?.id}&variant_id=${product.variant.id}`,
              }
            }
            // console.log(prod);
            setOrders(prev => ([...prev, prod]));
          });
          // setOrders(data.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function getBillingDetails() {
    const bearerToken = localStorage.getItem("accessToken");
    // console.log("bearerToken", orders);

    const api = apiConfig.getBillingAdddress;
    fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("BillingDetails:", data);
        getStates(data?.address?.country?.id, 'billing');
        setState({
          id: data?.address?.id,
          name: data?.address.name,
          address1: data.address?.address,
          address2: data?.address?.address2 || '',
          phone: data.address?.mobile,
          country: data.address?.country?.id,
          state: data.address?.state.id,
          // city: data.address?.city.id,
          postcode: data.address?.pincode,
          email: data.address?.email
        });
        // setAddressData(data?.address);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  function getShippingDetails() {
    const bearerToken = localStorage.getItem("accessToken");
    // console.log("bearerToken", orders);

    const api = apiConfig.getAddressAPI;
    fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("ShippingDetails:", data);
        getStates(data?.address?.country?.id, 'shipping');
        setShipState({
          id: data?.address?.id,
          name: data?.address.name,
          address: data.address?.address,
          address2: data?.address?.address2 || '',
          phone: data.address?.phone,
          country: data.address?.country?.id,
          state: data.address?.state.id,
          // city: data.address?.city.id,
          postcode: data.address?.pin_code,
          email: data.address?.email
        });
        // setAddressData(data?.address);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  function getCountryStates() {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      apiConfig.getCountryStateAPI,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setStateOptions(result?.states);
        // console.log("states", stateOptions);
      })
      .catch((error) => console.log("error", error));
  }

  function getCountries() {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      apiConfig.getCountriesAPI,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log("countries", result.countries);
        setCountriesOptions(result?.countries);
      })
      .catch((error) => console.log("error", error));
  }
  function getStates(id, type) {
    // console.log(id, type);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${apiConfig.getStatesAPI}/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("states", result);
        setStateOptions(prev => ({ ...prev, [type]: result?.states }));
      })
      .catch((error) => console.log("error", error));
  }

  useEffect(() => {
    getCartDetails();
    getBillingDetails();
    getShippingDetails();
    getCountries();
  }, []);

  return (
    <div id="site-main" className="site-main">
      <div id="main-content" className="main-content">
        <div id="primary" className="content-area">
          {/* Page Title */}
          <PageTitle current={t("Check.Shop Checkout")} />

          <div id="content" className="site-content" role="main">
            <div className="section-padding">
              <div className="section-container p-l-r">
                <div className="shop-checkout">
                  <form
                    name="checkout"
                    method="post"
                    className="checkout"
                    action=""
                    autocomplete="off"
                  >
                    <div className="row">
                      <div className="d-grid col-xl-8 col-lg-7 col-md-12 col-12">
                        <div className="customer-details">
                          <div className="billing-fields">
                            <h3>{t("Check.Billing details")}</h3>
                            <div className="billing-fields-wrapper">
                              <p className="form-row form-row-first validate-required">
                                <label>
                                  {t("Check.Full Name")}{" "}
                                  <span className="required" title="required">
                                    *
                                  </span>
                                </label>
                                <span className="input-wrapper">
                                  <input
                                    type="text"
                                    className="input-text"
                                    name="name"
                                    value={state?.name}
                                    onChange={handleChange}
                                  />
                                </span>
                              </p>
                              <p className="form-row my-5 form-row-wide validate-required validate-phone">
                                <label>
                                  {t("Check.Phone")}{" "}
                                  <span className="required" title="required">
                                    *
                                  </span>
                                </label>
                                <span className="input-wrapper">
                                  <input
                                    type="tel"
                                    className="input-text"
                                    name="phone"
                                    value={state?.phone}
                                    onChange={handleChange}
                                  />
                                </span>
                              </p>
                              <p className="form-row my-5 form-row-wide validate-required validate-email">
                                <label>
                                  {t("Check.Email address")}{" "}
                                  <span className="required" title="required">
                                    *
                                  </span>
                                </label>
                                <span className="input-wrapper">
                                  <input
                                    type="email"
                                    className="input-text"
                                    name="email"
                                    value={state?.email}
                                    autocomplete="off"
                                    onChange={handleChange}
                                  />
                                </span>
                              </p>
                              <p className="form-row my-5 address-field validate-required form-row-wide">
                                <label>
                                  {t("Check.Street address")}{" "}
                                  <span className="required" title="required">
                                    *
                                  </span>
                                </label>
                                <span className="input-wrapper">
                                  <input
                                    type="text"
                                    className="input-text"
                                    name="address1"
                                    placeholder="House number and street name"
                                    value={state?.address1}
                                    onChange={handleChange}
                                  />
                                </span>
                              </p>
                              <p className="form-row my-5 form-row-wide validate-required">
                                <label>
                                  {t("Check.Country")} {" "}
                                  <span className="required" title="required">
                                    *
                                  </span>
                                </label>
                                <span className="input-wrapper">
                                  <select
                                    name="country"
                                    className="country-select custom-select w-100"
                                    value={state?.country} // Set the selected option based on state
                                    onChange={handleChange}
                                  >
                                    <option value="" key={0}> Select Country</option>
                                    {countriesOptions?.map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.name}
                                      </option>
                                    ))}
                                  </select>
                                </span>
                              </p>
                              <p className="form-row my-5 address-field validate-required validate-state form-row-wide">
                                <label>
                                  {t("Check.State")} {" "}
                                  <span className="required" title="required">
                                    *
                                  </span>
                                </label>
                                <span className="input-wrapper">
                                  <select
                                    name="state"
                                    className="state-select custom-select w-100"
                                    value={state.state} // Set the selected option based on state
                                    onChange={handleChange} // Add an onChange event handler
                                  >
                                    <option value="" key={0}> Select State</option>
                                    {stateOptions?.billing?.map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.name}
                                      </option>
                                    ))}
                                  </select>
                                </span>
                              </p>
                              <p className="form-row my-5 address-field validate-required validate-postcode form-row-wide">
                                <label>
                                  {t("Check.Postcode / ZIP")}{" "}
                                  <span className="required" title="required">
                                    *
                                  </span>
                                </label>
                                <span className="input-wrapper">
                                  <input
                                    type="text"
                                    className="input-text"
                                    name="postcode"
                                    value={state.postcode}
                                    onChange={handleChange}
                                  />
                                </span>
                              </p>
                            </div>
                            <br />
                            <div
                              style={{
                                background: "black",
                                color: "white",
                                display: "flex",
                                justifyContent: "center",
                                width: "140px",
                                cursor: "pointer",
                                marginBottom: "10px",
                                height: '40px',
                                lineHeight: '37px',
                                textTransform: 'uppercase',
                                fontWeight: '400',
                                letterSpacing: '0.05rem',
                                fontSize: '14px'
                              }}
                              onClick={addOrUpdateBillingAddress}
                            >
                              {state ? t("Check.Update Address") : t("Check.Add Address")}
                            </div>
                            <br />
                          </div>
                          <p className="form-row form-row-wide ship-to-different-address">
                            <label className="checkbox">
                              <input
                                className="input-checkbox"
                                type="checkbox"
                                name="ship_to_different_address"
                                value="1"
                                checked={shipToDifferentAddress} // Set the checkbox's checked state from the state
                                onChange={handleCheckboxChange} // Add an onChange event handler
                              />
                              <span>{t("Check.Same as Billing Address")}</span>
                            </label>
                          </p>
                        </div>

                        <div className="customer-details">
                          <div className="billing-fields">
                            {!shipToDifferentAddress && (
                              <>
                                <h3>{t("Check.Shipping details")}</h3>
                                <div className="billing-fields-wrapper">
                                  <p className="form-row  form-row-first validate-required">
                                    <label>
                                      {t("Check.Full Name")}{" "}
                                      <span
                                        className="required"
                                        title="required"
                                      >
                                        *
                                      </span>
                                    </label>
                                    <span className="input-wrapper">
                                      <input
                                        type="text"
                                        className="input-text"
                                        name="name"
                                        value={shipState?.name}
                                        onChange={handlesChange}
                                      />
                                    </span>
                                  </p>
                                  <p className="form-row my-5 form-row-wide validate-required validate-phone">
                                    <label>
                                      {t("Check.Phone")}{" "}
                                      <span
                                        className="required"
                                        title="required"
                                      >
                                        *
                                      </span>
                                    </label>
                                    <span className="input-wrapper">
                                      <input
                                        type="tel"
                                        className="input-text"
                                        name="phone"
                                        value={shipState?.phone}
                                        onChange={handlesChange}
                                      />
                                    </span>
                                  </p>
                                  <p className="form-row my-5 form-row-wide validate-required validate-email">
                                    <label>
                                      {t("Check.Email address")}{" "}
                                      <span
                                        className="required"
                                        title="required"
                                      >
                                        *
                                      </span>
                                    </label>
                                    <span className="input-wrapper">
                                      <input
                                        type="email"
                                        className="input-text"
                                        name="email"
                                        value={shipState?.email}
                                        autocomplete="off"
                                        onChange={handlesChange}
                                      />
                                    </span>
                                  </p>
                                  <p className="form-row my-5 address-field validate-required form-row-wide">
                                    <label>
                                      {t("Check.Street address")}{" "}
                                      <span
                                        className="required"
                                        title="required"
                                      >
                                        *
                                      </span>
                                    </label>
                                    <span className="input-wrapper">
                                      <input
                                        type="text"
                                        className="input-text"
                                        name="address"
                                        placeholder="House number and street name"
                                        value={shipState?.address}
                                        onChange={handlesChange}
                                      />
                                    </span>
                                  </p>
                                  <p className="form-row  my-5 form-row-wide validate-required">
                                    <label>
                                      {t("Check.Country")}{" "}
                                      <span
                                        className="required"
                                        title="required"
                                      >
                                        *
                                      </span>
                                    </label>
                                    <span className="input-wrapper">
                                      <select
                                        name="country"
                                        className="country-select custom-select w-100"
                                        value={shipState?.country} // Set the selected option based on state
                                        onChange={handlesChange}
                                      >
                                        <option value="" key={0}>Select Country</option>
                                        {countriesOptions?.map((option) => (
                                          <option
                                            key={option.id}
                                            value={option.id}
                                          >
                                            {option.name}
                                          </option>
                                        ))}
                                      </select>
                                    </span>
                                  </p>
                                  <p className="form-row my-5 address-field validate-required validate-state form-row-wide">
                                    <label>
                                      {t("Check.State")}{" "}
                                      <span
                                        className="required"
                                        title="required"
                                      >
                                        *
                                      </span>
                                    </label>
                                    <span className="input-wrapper">
                                      <select
                                        name="state"
                                        className="state-select custom-select w-100"
                                        value={shipState?.state} // Set the selected option based on state
                                        onChange={handlesChange} // Add an onChange event handler
                                      >
                                        <option value="" key={0}>Select State</option>
                                        {stateOptions?.shipping?.map((option) => (
                                          <option
                                            key={option.id}
                                            value={option.id}
                                          >
                                            {option.name}
                                          </option>
                                        ))}
                                      </select>
                                    </span>
                                  </p>
                                  <p className="form-row my-5 address-field validate-required validate-postcode form-row-wide">
                                    <label>
                                      {t("Check.Postcode / ZIP")}{" "}
                                      <span
                                        className="required"
                                        title="required"
                                      >
                                        *
                                      </span>
                                    </label>
                                    <span className="input-wrapper">
                                      <input
                                        type="text"
                                        className="input-text"
                                        name="postcode"
                                        value={shipState?.postcode}
                                        onChange={handlesChange}
                                      />
                                    </span>
                                  </p>
                                </div>
                                <br />
                                <div
                                  style={{
                                    background: "black",
                                    color: "white",
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "140px",
                                    cursor: "pointer",
                                    marginBottom: "10px",
                                    height: '40px',
                                    lineHeight: '37px',
                                    textTransform: 'uppercase',
                                    fontWeight: '400',
                                    letterSpacing: '0.05rem',
                                    fontSize: '14px'

                                  }}
                                  onClick={addOrUpdateAddress}
                                >
                                  {shipState ? t("Check.Update Address") : t("Check.Add Address")}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-5 col-md-12 col-12">
                        <div className="checkout-review-order">
                          <div className="checkout-review-order-table">
                            <div className="review-order-title">{t("Check.Products")}</div>
                            {orders?.map((order) => (
                              <div className="cart-items">
                                <div className="cart-item">
                                  <div className="info-product">
                                    <div className="product-thumbnail">
                                      <Link to={order.link}>
                                        <img
                                          width="600"
                                          height="600"
                                          src={`${order?.image_path}/${order?.image}`}
                                          alt=""
                                        />
                                      </Link>
                                    </div>
                                    <div className="product-name">
                                      <Link
                                        to={order.link}
                                      >
                                        {
                                          order?.name
                                        }
                                      </Link>
                                      <strong className="product-quantity">
                                        {t("Check.QTY")}{" :"}
                                        {parseInt(order?.qty)}
                                      </strong>
                                    </div>
                                  </div>
                                  <div className="product-total">
                                    <span>
                                      KD{" "}

                                      {
                                        order?.price
                                      }
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                            <div className="cart-subtotal">
                              <h2>{t("Check.Subtotal")}</h2>
                              <div className="subtotal-price">
                                <span>KD {Math.round(costs?.sub_total * 100) / 100}</span>
                              </div>
                            </div>

                            <div className="cart-subtotal">
                              <h2>{t("Check.Shipping Charge")}</h2>
                              <div className="subtotal-price">
                                <span>KD {Math.round(costs?.shipping * 100) / 100}</span>
                              </div>
                            </div>
                            <div className="cart-subtotal">
                              <h2>{t("Check.Discount")}</h2>
                              <div className="subtotal-price">
                                <span>KD {Math.round(costs?.discount * 100) / 100}</span>
                              </div>
                            </div>
                            <div className="order-total">
                              <h2>{t("Check.Total")}</h2>
                              <div className="total-price">
                                <strong>
                                  <span>KD {Math.round(costs?.grand_total * 100) / 100}</span>
                                </strong>
                              </div>
                            </div>
                          </div>
                          <div id="payment" className="checkout-payment">
                            <ul className="payment-methods methods custom-radio">
                              {/* <li className="payment-method">
                                <input
                                  type="radio"
                                  className="input-radio"
                                  name="payment_method"
                                  value="bacs"
                                  checked="checked"
                                />
                                <label for="payment_method_bacs">
                                  Direct bank transfer
                                </label>
                                <div className="payment-box">
                                  <p>
                                    Make your payment directly into our bank
                                    account. Please use your Order ID as the
                                    payment reference. Your order will not be
                                    shipped until the funds have cleared in our
                                    account.
                                  </p>
                                </div>
                              </li>
                              <li className="payment-method">
                                <input
                                  type="radio"
                                  className="input-radio"
                                  name="payment_method"
                                  value="cheque"
                                />
                                <label>Check payments</label>
                                <div className="payment-box">
                                  <p>
                                    Please send a check to Store Name, Store
                                    Street, Store Town, Store State / County,
                                    Store Postcode.
                                  </p>
                                </div>
                              </li> */}
                              <li className="payment-method">
                                <input
                                  type="radio"
                                  className="input-radio"
                                  name="payment_method"
                                  value="cod"
                                  checked="checked"
                                />
                                <label>{t("Check.Cash on delivery")}</label>
                                <div className="payment-box">
                                  <p>{t("Check.Pay with cash upon delivery.")}</p>
                                </div>
                              </li>
                              {/* <li className="payment-method">
                                <input
                                 checked="checked"
                                  type="radio"
                                  className="input-radio"
                                  name="payment_method"
                                  value="paypal"
                                />
                                <label>PayPal</label>
                                <div className="payment-box">
                                  <p>
                                    Pay via PayPal; you can pay with your credit
                                    card if you don’t have a PayPal account.
                                  </p>
                                </div>
                              </li> */}
                            </ul>
                            <div className="form-row place-order">
                              <div className="terms-and-conditions-wrapper">
                                <div className="privacy-policy-text"></div>
                              </div>
                              <Button
                                type="submit"
                                className="button alt"
                                name="checkout_place_order"
                                value="Place order"
                                onClick={(e) => placeOrder(e)}
                                disabled={buttonClicked}
                              >
                                {buttonClicked ? t("Check.Please wait") : t("Check.Place Order")}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopCheckout;
